import Axios from 'axios';

import { axiosCloudConfig, axiosCloudConfigForRemoveController, axiosConfig, axiosKVSConfig } from './config';
import {
    API_CLOUD_EZLO_REQUEST_URL,
    GET_PARTNER_UUID_KVS,
    GET_ALL_USER,
    EZLO_GET_TICKET_PRIORITIES,
    EZLO_CREATE_SUPPORT_TICKET,
    EZLO_GET_USERS_FROM_KVS,
    KVS_GET,
    // TODO: apply refactoring, no relation to scenes, no return in naming
    returnKVSObject,
    EZLO_GET_TICKET_HELPTOPICS,
    // TODO: apply refactoring, no relation to scenes, no return in naming
    returnKVSObjectForSubscription,
    // TODO: apply refactoring, no relation to scenes, no return in naming
    returnKVSParams,
    PARTNER_DOMAIN_TO_PARTNER_UUID,
    getParamsForPartnerDomain,
    GET_EULA_URL,
    GET_NOTICES_URL,
    GET_CONSENT_URL,
    SET_EULA_URL,
    SET_NOTICES_URL,
    SET_CONSENT_URL,
    SET_COUNTRY_URL,
} from '../constants/URLs';
import { bugsnagNotifyWrapper } from '../containers/ErrorBoundary/utils';
import {
    KVS_USER_KEY,
    LOCATION,
    TICKET_DATA,
    TICKET_USER,
    VERA_USER,
    VERSION,
} from '../containers/Ezlo/EzloCustomization/constants';

import {
    NOTIFICATION_CATEGORIES_LIST,
    NOTIFICATION_CATEGORIES_SET_ALL,
    NOTIFICATION_CATEGOROES_SETTINGS_LIST,
    NOTIFICATION_CATEGORY_SETTINGS_SET,
    NOTIFICATION_LIST_CHANNELS,
    PARTNER_MESHBOT_TEMPLATES_LIST,
    PARTNER_MESHBOT_TEMPLATES_SET,
} from '../constants/NotificationTemplates';
import { buildKvsGetParam } from '../services/kvs';

export const axiosPost = (data) => {
    return Axios.post(API_CLOUD_EZLO_REQUEST_URL(), data, axiosCloudConfig()).then((res) => res.data);
};

export const apiGetMeshScenes = () => {
    const data = {
        call: 'scene_list',
        version: '1',
    };

    return Axios.post(API_CLOUD_EZLO_REQUEST_URL(), data, axiosCloudConfig());
};

export const apiGetCustomizationData = (partner_uuid) => {
    const data = {
        call: KVS_GET,
        params: returnKVSObject(partner_uuid),
        version: VERSION,
    };

    return Axios.post(API_CLOUD_EZLO_REQUEST_URL(), data, axiosCloudConfig());
};

export const apiGetCustomizationForUser = () => {
    const data = {
        call: KVS_GET,
        params: returnKVSParams(KVS_USER_KEY, VERA_USER),
        version: VERSION,
    };

    return Axios.post(API_CLOUD_EZLO_REQUEST_URL(), data, axiosCloudConfig());
};

export const apiGetSubscriptionData = (subscription_id) => {
    const data = {
        call: KVS_GET,
        params: returnKVSObjectForSubscription(subscription_id),
        version: VERSION,
    };

    return Axios.post(API_CLOUD_EZLO_REQUEST_URL(), data, axiosCloudConfig());
};

export const apiGetMeshbotConfigData = (unique_key, type) => {
    const data = {
        call: KVS_GET,
        params: buildKvsGetParam(unique_key, type),
        version: VERSION,
    };

    return Axios.post(API_CLOUD_EZLO_REQUEST_URL(), data, axiosCloudConfig());
};

export const apiGetEulaData = () => {
    return Axios.get(GET_EULA_URL(), axiosConfig());
};

export const apiSetEulaData = (eulaData) => {
    return Axios.post(SET_EULA_URL(), eulaData, axiosConfig());
};

export const apiGetNoticesData = () => {
    return Axios.get(GET_NOTICES_URL(), axiosConfig());
};

export const apiSetNoticesData = (noticesData) => {
    return Axios.post(SET_NOTICES_URL(), noticesData, axiosConfig());
};

export const apiGetConsentData = () => {
    return Axios.get(GET_CONSENT_URL(), axiosConfig());
};

export const apiSetConsentData = (setConsentData) => {
    return Axios.post(SET_CONSENT_URL(), setConsentData, axiosConfig());
};

export const apiSetCountryData = (countryData) => {
    return Axios.post(SET_COUNTRY_URL(), countryData, axiosConfig());
};

export const apiGetCustomizationDataForDomain = (partner_uuid) => {
    const data = {
        call: KVS_GET,
        params: returnKVSObject(partner_uuid),
        version: VERSION,
    };

    return Axios.post(API_CLOUD_EZLO_REQUEST_URL(), data);
};

export const getPartnerUuid = (PK_Account) => {
    return Axios.get(GET_PARTNER_UUID_KVS(PK_Account), axiosCloudConfigForRemoveController());
};

export const getPartnerUuidFromTLD = (hostname) => {
    const data = {
        call: PARTNER_DOMAIN_TO_PARTNER_UUID,
        params: getParamsForPartnerDomain(hostname),
    };

    return Axios.post(API_CLOUD_EZLO_REQUEST_URL(), data);
};

export const getUsersFromKvs = (authorization) => {
    return Axios.get(EZLO_GET_USERS_FROM_KVS, axiosKVSConfig(authorization));
};

export const getListOfPriorities = (authorization) => {
    return Axios.get(EZLO_GET_TICKET_PRIORITIES, axiosKVSConfig(authorization));
};

export const getListOfHelptopics = (authorization) => {
    return Axios.get(EZLO_GET_TICKET_HELPTOPICS, axiosKVSConfig(authorization));
};

export const createSupportTicket = (params, authorization) => {
    return Axios.post(EZLO_CREATE_SUPPORT_TICKET, params, axiosKVSConfig(authorization));
};

/**
 * Download ticket data content by partner uuid value.
 *
 * @async
 * @function apiGetTicketDataByPartnerUuid
 * @param {string} partnerUuid - partner's uuid value.
 * @return {Promise<Object>} The ticket_data content.
 * */
export const apiGetTicketDataByPartnerUuid = (partnerUuid) => {
    const data = {
        call: KVS_GET,
        params: {
            list: [
                {
                    key: TICKET_DATA,
                    entity_uuid: partnerUuid,
                    type: LOCATION,
                },
            ],
        },
    };

    return Axios.post(API_CLOUD_EZLO_REQUEST_URL(), data, axiosCloudConfig());
};

export const getUserUuidForCreateTicket = () => {
    const data = {
        call: KVS_GET,
        params: {
            list: [
                {
                    key: TICKET_USER,
                    type: LOCATION,
                },
            ],
        },
    };

    return Axios.post(API_CLOUD_EZLO_REQUEST_URL(), data, axiosCloudConfig());
};

export const apiSetDataToKvs = (params) => {
    const data = {
        call: 'kvs_set',
        params: params,
        version: '1',
    };

    return Axios.post(API_CLOUD_EZLO_REQUEST_URL(), data, axiosCloudConfig());
};

export const getResponseJavaScriptCode = (code, testButtonRes) => {
    const data = {
        call: 'code_test_run',
        version: '1',
        params: {
            input: {
                context: {},
                raw_string: '',
                raw_json: testButtonRes,
            },
            code: code,
            inject: 0,
            language: 'javascript',
        },
    };

    return Axios.post(API_CLOUD_EZLO_REQUEST_URL(), data, axiosCloudConfig());
};

export const apiGetOneMeshScene = (uuid) => {
    const data = {
        call: 'scene_get',
        params: {
            uuid,
        },
    };

    return Axios.post(API_CLOUD_EZLO_REQUEST_URL(), data, axiosCloudConfig());
};

export const apiSetMeshScenes = (params) => {
    const data = {
        call: 'scene_set',
        version: '1',
        params: params,
    };

    return Axios.post(API_CLOUD_EZLO_REQUEST_URL(), data, axiosCloudConfig());
};

export const apiRunMeshScenes = (uuid) => {
    const data = {
        call: 'scene_run',
        version: '1',
        params: {
            uuid,
        },
    };

    return Axios.post(API_CLOUD_EZLO_REQUEST_URL(), data, axiosCloudConfig());
};

export const apiSetNotificationTemplate = (params) => {
    const data = {
        call: PARTNER_MESHBOT_TEMPLATES_SET,
        params: params,
    };

    return Axios.post(API_CLOUD_EZLO_REQUEST_URL(), data, axiosCloudConfig());
};

export const apiSetNotificationCategory = (params) => {
    const data = {
        call: NOTIFICATION_CATEGORIES_SET_ALL,
        params,
    };

    return Axios.post(API_CLOUD_EZLO_REQUEST_URL(), data, axiosCloudConfig());
};

export const apiSetNotificationCategorySettings = (params) => {
    const data = {
        call: NOTIFICATION_CATEGORY_SETTINGS_SET,
        params,
    };

    return Axios.post(API_CLOUD_EZLO_REQUEST_URL(), data, axiosCloudConfig());
};

export const apiGetNotificationCategories = async () => {
    const data = {
        call: NOTIFICATION_CATEGORIES_LIST,
        params: {},
    };

    return Axios.post(API_CLOUD_EZLO_REQUEST_URL(), data, axiosCloudConfig());
};

export const apiGetNotificationCategoriesSettingsList = async () => {
    const data = {
        call: NOTIFICATION_CATEGOROES_SETTINGS_LIST,
        params: {},
    };

    return Axios.post(API_CLOUD_EZLO_REQUEST_URL(), data, axiosCloudConfig());
};

export const apiGetMeshSceneDetails = () => {
    const data = {
        call: 'abstract_list_detailed',
        params: {},
    };

    return Axios.post(API_CLOUD_EZLO_REQUEST_URL(), data, axiosCloudConfig());
};

export const apiGetMeshSceneCapabilities = () => {
    const data = {
        call: 'abstract_capabilities_list',
        params: {},
    };

    return Axios.post(API_CLOUD_EZLO_REQUEST_URL(), data, axiosCloudConfig());
};

//---------------------------cloud meshbot------------------------

export const apiGetCloudMeshBot = () => {
    const data = {
        call: 'scene_list',
        version: '1',
    };

    return axiosPost(data);
};

export const apiGetAbstractList = () => {
    // const data = {
    //     call: 'abstract_list',
    //     params: {},
    // };
    /**New version request**/
    const data = {
        call: 'abstract_list',
        params: {
            meta: 1,
        },
        version: '2',
    };

    return axiosPost(data);
};

export const apiGetCapabilities = () => {
    const data = {
        call: 'abstract_capabilities_list',
        params: {},
    };

    return axiosPost(data);
};

export const apiGetAllUser = async () => {
    const res = await Axios.get(GET_ALL_USER(), axiosConfig());
    if (res && res.data && res.data.Users) {
        return res.data.Users;
    }
};

export const apiGetChannels = async () => {
    const data = {
        call: NOTIFICATION_LIST_CHANNELS,
        params: {},
    };
    const res = await axiosPost(data);
    if (res && res.data && res.data.channels) {
        return res.data.channels;
    }
};

export const apiNotificationTemplate = async () => {
    const data = {
        call: PARTNER_MESHBOT_TEMPLATES_LIST,
        version: '2',
    };

    return Axios.post(API_CLOUD_EZLO_REQUEST_URL(), data, axiosCloudConfig());
};
/**
 * Returns Username by User's Personal Key from User Data Object from array of User Data Objects
 * @param {Object} dashboardsList - Object with Dashboard's UUID as keys and Dashboard Data Object as values
 * @returns {array} array of objects with following structure:
 * {
 * uuid: '224eded4-5a85-1353-e316-b012e413d225',
 * name: 'My dashboardname',
 * last_modified: 1643989104.406,
 * type: 'dashboard'
 * }
 * */

export const createDashboardsDataArray = (dashboardsList) => {
    if (dashboardsList === null || typeof dashboardsList !== 'object' || Array.isArray(dashboardsList)) {
        throw new Error('#createDashbordsDataArray: dashbordsList is not an object');
    }

    return Object.values(dashboardsList);
};

export const apiGetDashboardsList = async () => {
    const dataRequest = {
        call: 'dashboard_templates_list',
        params: { type: 'dashboard' },
    };

    try {
        const {
            data: { dashboards },
        } = await axiosPost(dataRequest);

        if (dashboards) {
            return createDashboardsDataArray(dashboards);
        }

        return [];
    } catch (error) {
        bugsnagNotifyWrapper(error);
    }
};

export const apiGetUserUuid = async (ids) => {
    const data = {
        call: 'legacy_id_mapping',
        params: {
            map: {
                user: {
                    ids_to_uuids: ids,
                },
            },
        },
    };
    const res = await axiosPost(data);
    if (res && res.data && res.data.map && res.data.map.user && res.data.map.user.ids_to_uuids) {
        return res.data.map.user.ids_to_uuids;
    }
};

/**
 * Api to get controllers uuids from PK_Devices
 * @param {String[]} PK_Devices - PK_Devices list
 * @returns {Object} will return a list of controller_uuid by controller PK_Device
 * @example
 * apiGetControllersUuid(['90002365', '7452369'])
 * More info {@link https://confluence.mios.com/display/EPD/Legacy+-+ID+Mapping here}
 */
export const apiGetControllersUuid = async (PK_Devices) => {
    const data = {
        call: 'legacy_id_mapping',
        params: {
            map: {
                controller: {
                    ids_to_uuids: PK_Devices,
                },
            },
        },
    };
    const res = await axiosPost(data);
    if (res?.data?.map?.controller?.ids_to_uuids) {
        return res.data.map.controller.ids_to_uuids;
    } else {
        return {};
    }
};

export const apiAbstractCommand = async (params) => {
    const data = {
        call: 'abstract_command',
        params,
        version: '1',
    };
    try {
        const response = await axiosPost(data);

        return response;
    } catch (error) {
        bugsnagNotifyWrapper(error);
    }
};

export const apiCodeTestRun = async (params) => {
    const data = {
        call: 'code_test_run',
        params,
        version: '1',
    };
    try {
        const response = await axiosPost(data);

        return response;
    } catch (error) {
        bugsnagNotifyWrapper(error);
    }
};

export const apiGetKvsDataByKey = (unique_key, type) => {
    const data = {
        call: KVS_GET,
        params: buildKvsGetParam(unique_key, type),
        version: VERSION,
    };

    return Axios.post(API_CLOUD_EZLO_REQUEST_URL(), data, axiosCloudConfig());
};
