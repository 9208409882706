import { CloudMeshbotActionTypes } from '../constants/ActionTypes/CloudMeshbot';
import _ from 'lodash';
import at from '../constants/ActionTypes/MeshBot';

import { getNameCapability } from '../helpers/helpersMeshBot';
import {
    APICALL_NAME,
    OPERATOR_NOT,
    OPERATOR_AND,
    OPERATOR_OR,
    OPERATOR_TYPE,
    PAAS_ACTION,
    SINGLE_TRIGGER,
    CAPABILITY,
    CAPABILITY_COMMANDS,
    CLOUD_NOTIFICATION,
    DASHBOARD,
    INPUT_TYPE,
    NOTIFICATION,
    TARGET,
    NAME,
    DEVICE_STATE,
    ABSTRACT,
    COMMAND,
    REQUIRED_FIELDS,
    INDEX_OF_ZERO,
    INDEX_OF_ONE,
    STATIC,
    STRING,
    AV,
    MESHBOT_NODE_TYPES,
    ON_CHANGE,
    USER_BLOCK_INDEX,
    GEOFENCES,
    SCHEDULE,
    SINGLE,
} from '../constants/MeshbotConstant';
import { SCHEDULE_DATE_TYPES } from '../components/blockCloudTriggerTemplate/dateTemplatesForCloudMeshbot';
import {
    updateNucalSubscriptionStruct,
    updateNucalCreateStruct,
    updateCloudVariableStruct,
    updateCloudVariableBlockStruct,
    updateNucalSubscriptionInKvsStatus,
    updateNucalSubscriptionDataFromKvs,
    generateDeviceBlock,
    updateDeviceSubBlock,
    updateDeviceCurrentVariable,
    createDefaultNucalBlockStruct,
    checkIfNucalTrigger,
} from '../containers/Ezlo/EzloMeshbot/utils';
import { updateEditState } from '../containers/Ezlo/EzloMeshbot/utils';
import {
    updateBlocksInCaseNotificationTemplateVariable,
    updateBlocksInCaseNotificationTemplateVariableValue,
} from '../containers/Ezlo/EzloVirtualDevice/utils';
import actions from './IntegrationsActions';
import { getUpdatedCloudVariableProperties } from '../containers/Ezlo/EzloMeshbot/components/CloudVariableForTrigger/utils';
import { STATUS } from '../constants/NotificationTemplates';
import { SCALE, TARGET_DELTA, TARGET_VALUE } from '../constants/DevicePluginGenerator';
import { MeshBotAction } from './index';
import {
    convertCloudMeshbotActionsStructureToUI,
    convertCloudMeshbotNestedTriggersStructureToUI,
    convertCloudMeshbotTriggersStructureToUI,
    convertDateAndTimeCloudMeshbotTriggersStructureToUI,
    convertGeofenceCloudMeshbotTriggersStructureToUI,
    convertNucalCloudMeshbotTriggersStructureToUI,
} from '../containers/Ezlo/EzloMeshbot/MeshbotCloud/MeshBotCloudCreate/convertCloudMeshbotStructureToUI';
import { hasDuplicateSubscriptions } from '../containers/Ezlo/utils';
import { toast, TOAST_TYPE } from '../components/Toast';
import { EZLOGIC_TITLE_YOU_CANNOT_USE_THE_SAME_METHOD } from '../constants/language_tokens';

const TRIGGER_WRAPPER_TYPE = {
    INGROUP: 'inGroup',
    GROUP: 'group',
};

// TODO move to utils
const observerTrigger = (blocks, funcUpdate, idGroup) => {
    if (idGroup) {
        return blocks.map((block) => {
            if (block.id === idGroup) {
                block.blocks = funcUpdate(block.blocks);

                return block;
            } else if (block.type === TRIGGER_WRAPPER_TYPE.GROUP) {
                observerTrigger(block.blocks, funcUpdate, idGroup);
            }

            return block;
        });
    }

    return funcUpdate(blocks);
};

const setCloudMeshBotNameBeforeEditing = (name) => {
    const { SET_CLOUD_MESHBOT_NAME_BEFORE_EDITING } = CloudMeshbotActionTypes;

    return (dispatch) => {
        dispatch({ type: SET_CLOUD_MESHBOT_NAME_BEFORE_EDITING, name });
    };
};

const setCloudMeshBotName = (name) => {
    const { SET_CLOUD_MESHBOT_NAME } = CloudMeshbotActionTypes;

    return (dispatch) => {
        dispatch({ type: SET_CLOUD_MESHBOT_NAME, name });
    };
};

const clearCloudCurrentScene = () => {
    const { CLEAR_CLOUD_CURRENT_SCENE } = CloudMeshbotActionTypes;

    return (dispatch) => {
        dispatch({ type: CLEAR_CLOUD_CURRENT_SCENE });
    };
};

const setCloudTriggersForEdit = (ruleCloudTriggers, parentOperator) => {
    return (dispatch, getState) => {
        const state = getState();
        let currentSceneTriggers = _.cloneDeep(state.meshBot.cloud?.currentScene?.triggers?.parameters);
        const { SET_CLOUD_TRIGGERS_FOR_EDIT } = CloudMeshbotActionTypes;

        const assignTriggerID = (currentScene) => {
            ruleCloudTriggers?.forEach((item, index) => {
                currentScene[index].id = item?.id;
            });

            return currentScene;
        };

        if (currentSceneTriggers?.length > SINGLE_TRIGGER) {
            currentSceneTriggers = assignTriggerID(currentSceneTriggers);
        }

        if (parentOperator === OPERATOR_AND || parentOperator === OPERATOR_OR) {
            dispatch({
                type: SET_CLOUD_TRIGGERS_FOR_EDIT,
                data: { ruleCloudTriggers, parentOperator, currentSceneTriggers },
            });
        } else {
            dispatch({ type: SET_CLOUD_TRIGGERS_FOR_EDIT, data: { ruleCloudTriggers } });
        }
    };
};

const setCloudActionsForEdit = (ruleCloudAction) => {
    return (dispatch) => {
        const { SET_CLOUD_ACTIONS_FOR_EDIT } = CloudMeshbotActionTypes;
        dispatch({ type: SET_CLOUD_ACTIONS_FOR_EDIT, ruleCloudAction });
    };
};

const updateCurrentCloudMeshscene = (currentScene) => {
    const { UPDATE_CURRENT_MESH_SCENE } = CloudMeshbotActionTypes;

    return (dispatch) => {
        dispatch({ type: UPDATE_CURRENT_MESH_SCENE, currentScene });
    };
};

const duplicateCloudMeshbot = (data) => {
    const { DUPLICATE_CURRENT_MESHBOT_SCENE } = CloudMeshbotActionTypes;

    return (dispatch) => {
        dispatch({ type: DUPLICATE_CURRENT_MESHBOT_SCENE, data });
    };
};

const duplicateCloudMeshbotName = (data) => {
    const { DUPLICATE_CURRENT_MESHBOT_SCENE_NAME } = CloudMeshbotActionTypes;

    return (dispatch) => {
        dispatch({ type: DUPLICATE_CURRENT_MESHBOT_SCENE_NAME, data });
    };
};

const addCloudTrigger = (data, idGroup, specialType) => {
    return (dispatch, getState) => {
        const state = getState();
        const { ADD_CLOUD_TRIGGER } = CloudMeshbotActionTypes;
        let ruleCloudTriggers = _.cloneDeep(state.meshBot.cloud.ruleCloudTriggers);

        const addTriggerInGroup = (triggers) => {
            return triggers.map((item) => {
                if (item.id === idGroup) {
                    if (specialType === TRIGGER_WRAPPER_TYPE.INGROUP) {
                        item.blocks = [...item.blocks, data];
                    } else {
                        item.blocks = [...item.blocks, { id: data.id, blocks: [], not: data.not }];
                    }

                    return item;
                } else {
                    if (item.type === TRIGGER_WRAPPER_TYPE.GROUP) {
                        addTriggerInGroup(item.blocks);
                    }
                }

                return item;
            });
        };

        if (idGroup) {
            ruleCloudTriggers = addTriggerInGroup(ruleCloudTriggers);
        } else {
            ruleCloudTriggers = [...ruleCloudTriggers, data];
        }

        dispatch({ type: ADD_CLOUD_TRIGGER, ruleCloudTriggers });
    };
};

const updateSelectedParentOperator = (value, id, field) => {
    return (dispatch, getState) => {
        const state = getState();
        let parentOperatorType = _.cloneDeep(state.meshBot.cloud.parentTriggerOptionType);

        const { UPDATE_SELECTED_PARENT_OPERATOR } = CloudMeshbotActionTypes;
        if (field === OPERATOR_TYPE && !id) {
            parentOperatorType = value;
        }

        dispatch({
            type: UPDATE_SELECTED_PARENT_OPERATOR,
            data: parentOperatorType,
        });
    };
};

const deleteCloudTrigger = (id, idGroup) => {
    return (dispatch, getState) => {
        const state = getState();
        const { DELETE_CLOUD_TRIGGER } = CloudMeshbotActionTypes;
        let ruleCloudTriggers = _.cloneDeep(state.meshBot.cloud.ruleCloudTriggers);

        const searchTrigger = (list) => {
            if (idGroup) {
                return list.map((item) => {
                    if (item.id === idGroup) {
                        item.blocks = item.blocks.filter((trigger) => trigger.id !== id);

                        return item;
                    } else if (item.type === TRIGGER_WRAPPER_TYPE.GROUP) {
                        searchTrigger(item.blocks);
                    }

                    return item;
                });
            }

            return list.filter((item) => item.id !== id);
        };

        ruleCloudTriggers = searchTrigger(ruleCloudTriggers);

        dispatch({ type: DELETE_CLOUD_TRIGGER, ruleCloudTriggers });
    };
};

const updateCloudTriggerForNuCAL = (cloudTriggerCreationData, cloudTriggerNucalSubscriptionData, id, field, t) => {
    return (dispatch, getState) => {
        const state = getState();
        const { UPDATE_CLOUD_TRIGGER_NUCAL } = CloudMeshbotActionTypes;
        let ruleCloudTriggers = _.cloneDeep(state.meshBot.cloud.ruleCloudTriggers);

        ruleCloudTriggers = updateNucalCreateStruct(id, ruleCloudTriggers, field, cloudTriggerCreationData);
        ruleCloudTriggers = updateNucalSubscriptionStruct(
            id,
            ruleCloudTriggers,
            field,
            cloudTriggerNucalSubscriptionData,
        );

        if (!hasDuplicateSubscriptions(ruleCloudTriggers)) {
            toast(t(EZLOGIC_TITLE_YOU_CANNOT_USE_THE_SAME_METHOD), {
                type: TOAST_TYPE.WARNING,
            });
        }
        dispatch({ type: UPDATE_CLOUD_TRIGGER_NUCAL, data: ruleCloudTriggers });
    };
};

const updateCloudNucalSubscriptionInKvsExists = (subscriptionData, trigger_id) => {
    return (dispatch, getState) => {
        const state = getState();

        const { UPDATE_CLOUD_NUCAL_SUBSCRIPTION_IN_KVS } = CloudMeshbotActionTypes;
        let ruleCloudTriggers = _.cloneDeep(state.meshBot.cloud.ruleCloudTriggers);

        if (subscriptionData.list.length) {
            ruleCloudTriggers = updateNucalSubscriptionInKvsStatus(trigger_id, ruleCloudTriggers, true);
        } else {
            ruleCloudTriggers = updateNucalSubscriptionInKvsStatus(trigger_id, ruleCloudTriggers, false);
        }

        dispatch({ type: UPDATE_CLOUD_NUCAL_SUBSCRIPTION_IN_KVS, data: ruleCloudTriggers });
    };
};

const updateCloudNucalSubscriptionValueFromKvs = (subscriptionData, trigger_id) => {
    return (dispatch, getState) => {
        const state = getState();

        const { UPDATE_CLOUD_NUCAL_SUBSCRIPTION_IN_KVS } = CloudMeshbotActionTypes;
        let ruleCloudTriggers = _.cloneDeep(state.meshBot.cloud.ruleCloudTriggers);

        ruleCloudTriggers = updateNucalSubscriptionDataFromKvs(trigger_id, ruleCloudTriggers, subscriptionData);
        ruleCloudTriggers = updateNucalSubscriptionInKvsStatus(trigger_id, ruleCloudTriggers, true);

        dispatch({ type: UPDATE_CLOUD_NUCAL_SUBSCRIPTION_IN_KVS, data: ruleCloudTriggers });
    };
};

const updateCloudVariablesTrigger = (variableNodeData, field, id) => {
    return (dispatch, getState) => {
        const state = getState();
        const { UPDATE_CLOUD_VARIABLE_TRIGGER } = CloudMeshbotActionTypes;
        let ruleCloudTriggers = _.cloneDeep(state.meshBot.cloud.ruleCloudTriggers);

        ruleCloudTriggers = updateCloudVariableStruct(id, ruleCloudTriggers, field, variableNodeData);

        dispatch({ type: UPDATE_CLOUD_VARIABLE_TRIGGER, data: ruleCloudTriggers });
    };
};

/**
 * Function that updates the trigger data about the selected variable in the storage
 * @param {Object} variableData - Selected variable data(cloudVariableName, abstract, integrationUuid)
 * @param {String} type - Field type for which the variable is selected
 * @param {String} ruleCloudTriggerId - Id of the trigger whose data needs to be updated
 * @returns {Function} - thunk function
 */
const updateCloudVariableInTrigger = (variableData, ruleCloudTriggerId, type) => (dispatch) => {
    const updatedCloudVariableProperties = getUpdatedCloudVariableProperties(variableData, type);

    dispatch(
        CloudMeshbotActions.updateCloudVariablesTrigger(
            updatedCloudVariableProperties,
            MESHBOT_NODE_TYPES.CLOUD_VARIABLES,
            ruleCloudTriggerId,
        ),
    );

    dispatch(actions.setCloudVariableCurrentValue(variableData.abstract.uuid, variableData.cloudVariableName));
};

const updateCloudVariablesTriggerBlocks = (variableNodeBlockData, field, id) => {
    return (dispatch, getState) => {
        const state = getState();
        const { UPDATE_CLOUD_VARIABLE_TRIGGER_BLOCKS } = CloudMeshbotActionTypes;
        let ruleCloudTriggers = _.cloneDeep(state.meshBot.cloud.ruleCloudTriggers);

        ruleCloudTriggers = updateCloudVariableBlockStruct(id, ruleCloudTriggers, field, variableNodeBlockData);

        dispatch({ type: UPDATE_CLOUD_VARIABLE_TRIGGER_BLOCKS, data: ruleCloudTriggers });
    };
};

const validateCloudTrigger = (value, id) => {
    return (dispatch, getState) => {
        const state = getState();
        const { UPDATE_CLOUD_TRIGGER } = CloudMeshbotActionTypes;
        let ruleCloudTriggers = _.cloneDeep(state.meshBot.cloud.ruleCloudTriggers);
        const update = (list) => {
            return list.map((item) => {
                if (item.id === id) {
                    item.isValid = value;
                }

                return item;
            });
        };
        ruleCloudTriggers = observerTrigger(ruleCloudTriggers, update);

        dispatch({ type: UPDATE_CLOUD_TRIGGER, data: ruleCloudTriggers });
    };
};

const validateCloudTriggerEdit = (value, id) => {
    return (dispatch, getState) => {
        const state = getState();
        const { UPDATE_CLOUD_TRIGGER } = CloudMeshbotActionTypes;
        let ruleCloudTriggers = _.cloneDeep(state.meshBot.cloud.ruleCloudTriggers);
        ruleCloudTriggers = updateEditState(ruleCloudTriggers, value, id);

        dispatch({ type: UPDATE_CLOUD_TRIGGER, data: ruleCloudTriggers });
    };
};

const validateCloudAction = (value, id) => {
    return (dispatch, getState) => {
        const state = getState();
        const { UPDATE_CLOUD_ACTIONS } = CloudMeshbotActionTypes;
        let ruleCloudAction = _.cloneDeep(state.meshBot.cloud.ruleCloudAction);
        const update = (list) => {
            return list.map((item) => {
                if (item.id === id) {
                    item.isValid = value;
                }

                return item;
            });
        };
        ruleCloudAction = observerTrigger(ruleCloudAction, update);

        dispatch({ type: UPDATE_CLOUD_ACTIONS, data: ruleCloudAction });
    };
};

const validateCloudActionEdit = (value, id) => {
    return (dispatch, getState) => {
        const state = getState();
        const { UPDATE_CLOUD_ACTIONS } = CloudMeshbotActionTypes;
        let ruleCloudAction = _.cloneDeep(state.meshBot.cloud.ruleCloudAction);
        ruleCloudAction = updateEditState(ruleCloudAction, value, id);

        dispatch({ type: UPDATE_CLOUD_ACTIONS, data: ruleCloudAction });
    };
};

const updateCloudTrigger = (value, id, idGroup, field) => {
    return (dispatch, getState) => {
        const state = getState();
        const { UPDATE_CLOUD_TRIGGER } = CloudMeshbotActionTypes;
        let ruleCloudTriggers = _.cloneDeep(state.meshBot.cloud.ruleCloudTriggers);
        const update = (list) => {
            return list.map((item) => {
                if (item.id === id) {
                    if (field === 'selectedOperator') {
                        item.variableValue = '';
                        item.selectedVariableCompared = '';
                        item.selectedValueType = '';
                        item.comparedVariableValue = '';
                        item.selectedAbstractForCompared = {};
                        item.blocks = generateDeviceBlock(item, value.selectedOperator);
                        item[field] = value;
                    }

                    if (field === at.NAME_SUB_BLOCK) {
                        item.nameSubBlock = value?.nameSubBlock;
                        item.currentVariable = value?.currentVariable;
                        item.variables = value?.variables;
                        item.selectedOperator = item?.selectedOperator === ON_CHANGE ? ON_CHANGE : '==';
                        item.variableValue = '';
                        item.capability = value?.nameSubBlock;
                        item.selectedVariableCompared = '';
                        item.selectedValueType = '';
                        item.comparedVariableValue = '';
                        item.selectedAbstractForCompared = {};
                        item.blocks = updateDeviceSubBlock(item, value?.nameSubBlock, value?.currentVariable);
                    }

                    if (field === at.NOTIFICATION_TEMPLATE_OPTION) {
                        item.capabilities = value?.capabilities;
                        item.typeOfNotificationTemplate = value?.typeOfNotificationTemplate;
                        item.blocks = value?.blocks;
                    }

                    if (field === at.NOTIFICATION_TEMPLATE_VARIABLE) {
                        item.currentVariable = value?.currentVariable;
                        item.currentVariableType = value?.currentVariableType;
                        item.selectedOperator = value?.currentVariable === STATUS ? '==' : item?.selectedOperator;
                        item.blocks = updateBlocksInCaseNotificationTemplateVariable(item, value);
                    }

                    if (field === at.NOTIFICATION_TEMPLATE_VARIABLE_VALUE) {
                        item.variableValue = value?.value;
                        item.isValidRgbValue = value?.isValidRgbValue;
                        item.blocks = updateBlocksInCaseNotificationTemplateVariableValue(item, value);
                    }

                    if (field === at.CLOUD_DEVICE && item.selectedOperator !== ON_CHANGE) {
                        item.capabilities = value?.capabilities;
                        item.variableValue = '';
                        item.target = {};
                        item.selectedOperator = '==';
                        item.selectedVariableCompared = '';
                        item.selectedValueType = '';
                        item.comparedVariableValue = '';
                        item.selectedAbstractForCompared = {};
                    }

                    if (field === at.VARIABLE) {
                        item.variableValue = '';
                        item.blocks = updateDeviceCurrentVariable(
                            item,
                            value?.currentVariable,
                            value?.currentVariableType,
                        );
                    }

                    if (field === at.VARIABLE_VALUE && item.selectedOperator !== ON_CHANGE) {
                        item.variableValue =
                            typeof value?.variableValue == STRING
                                ? value?.variableValue
                                : value?.variableValue[INDEX_OF_ZERO]?.value;
                        item.isValidRgbValue = value.isValidRgbValue;
                        item.blocks = item?.blocks?.map((blockItem) => {
                            delete blockItem.parameters[INDEX_OF_ONE].parameters[INDEX_OF_ZERO].abstract;
                            delete blockItem.parameters[INDEX_OF_ONE].parameters[INDEX_OF_ZERO].capability;
                            delete blockItem.parameters[INDEX_OF_ONE].parameters[INDEX_OF_ZERO].variable;
                            blockItem.name = item.selectedOperator;
                            blockItem.parameters[INDEX_OF_ONE].name = STATIC;
                            blockItem.parameters[INDEX_OF_ONE].parameters[INDEX_OF_ZERO].value = value?.variableValue;
                            blockItem.parameters[INDEX_OF_ONE].parameters[INDEX_OF_ZERO].transform =
                                value?.currentVariableType;

                            return blockItem;
                        });
                    }

                    if (field === at.CLOUD_VARIABLES) {
                        item.variableValue = value?.cloudVariableName;
                        item.blocks = item.blocks.map((blockItem) => {
                            blockItem.parameters[INDEX_OF_ONE].name = AV;
                            blockItem.parameters[INDEX_OF_ONE].parameters[INDEX_OF_ZERO].abstract =
                                value?.abstract?.uuid;
                            blockItem.parameters[INDEX_OF_ONE].parameters[INDEX_OF_ZERO].capability =
                                value?.cloudVariableName;
                            blockItem.parameters[INDEX_OF_ONE].parameters[INDEX_OF_ZERO].variable = at.VALUE;
                            delete blockItem.parameters[INDEX_OF_ONE].parameters[INDEX_OF_ZERO].transform;
                            delete blockItem.parameters[INDEX_OF_ONE].parameters[INDEX_OF_ZERO].value;

                            return blockItem;
                        });
                    }

                    if (value === MESHBOT_NODE_TYPES.PAAS) {
                        item.blocks = createDefaultNucalBlockStruct();
                    }

                    if (
                        value.hasOwnProperty(at.BLOCKS) &&
                        field !== at.NOTIFICATION_TEMPLATE_VARIABLE &&
                        field !== at.NOTIFICATION_TEMPLATE_VARIABLE_VALUE &&
                        field !== at.NOTIFICATION_TEMPLATE_CAPABILITY &&
                        field !== at.NOTIFICATION_TEMPLATE_OPTION &&
                        field !== at.NOTIFICATION_TEMPLATE
                    ) {
                        item.target = {};
                        item.variableValue = '';
                        value.blocks = Array.from(value.blocks);
                        item.blocks = [...value.blocks];
                    }

                    if (typeof value === 'object') {
                        item = _.merge(item, value);
                    } else {
                        field === OPERATOR_NOT ? (item.not = !item.not) : (item[field] = value);
                    }

                    return item;
                }

                return item;
            });
        };

        if (field === OPERATOR_TYPE && !id) {
            ruleCloudTriggers.optionType = value;
        }

        ruleCloudTriggers = observerTrigger(ruleCloudTriggers, update, idGroup);

        dispatch({ type: UPDATE_CLOUD_TRIGGER, data: ruleCloudTriggers });
    };
};

const updateCloudTriggerFields = (value, id, idGroup, field) => {
    return (dispatch, getState) => {
        const state = getState();
        let ruleCloudTriggers = _.cloneDeep(state.meshBot.cloud.ruleCloudTriggers);
        const update = (list) => {
            return list.map((item) => {
                if (item.id === id) {
                    if (field === MESHBOT_NODE_TYPES.GEOFENCE) {
                        item.blocks[0] = value;
                    }

                    return item;
                }

                return item;
            });
        };

        ruleCloudTriggers = observerTrigger(ruleCloudTriggers, update, idGroup);
        dispatch({ type: CloudMeshbotActionTypes.UPDATE_CLOUD_TRIGGER, data: ruleCloudTriggers });
    };
};

const clearTrigger = (id, idGroup) => {
    return (dispatch, getState) => {
        const state = getState();
        const { CLEAR_TRIGGER } = CloudMeshbotActionTypes;
        let ruleCloudTriggers = _.cloneDeep(state.meshBot.cloud.ruleCloudTriggers);

        const update = (list) => {
            return list.map((item) => {
                if (item.id === id) {
                    item = {
                        id: item.id,
                        blocks: [{}],
                        not: false,
                        isValid: false,
                        isEdit: true,
                    };

                    return item;
                }

                return item;
            });
        };

        ruleCloudTriggers = observerTrigger(ruleCloudTriggers, update, idGroup);

        dispatch({ type: CLEAR_TRIGGER, ruleCloudTriggers });
    };
};

const updateCloudTriggerBlock = (id, value, name, idGroup) => {
    //TODO :move to index.js
    const convertToMeshbotParameterValue = (data) => {
        if (typeof data === 'number') {
            return String(value);
        }

        return data;
    };

    return (dispatch, getState) => {
        const state = getState();
        const { UPDATE_CLOUD_TRIGGER_BLOCK } = CloudMeshbotActionTypes;
        let ruleCloudTriggers = _.cloneDeep(state.meshBot.cloud.ruleCloudTriggers);

        const update = (list) => {
            return list.map((item) => {
                if (item.id === id) {
                    item.blocks.map((block) => {
                        if (getNameCapability(block) === name) {
                            block.parameters[1].parameters[0].value = convertToMeshbotParameterValue(value);

                            return block;
                        }

                        return block;
                    });

                    return item;
                }

                return item;
            });
        };

        ruleCloudTriggers = observerTrigger(ruleCloudTriggers, update, idGroup);

        dispatch({ type: UPDATE_CLOUD_TRIGGER_BLOCK, ruleCloudTriggers });
    };
};

const getParametersByType = (rule, parameterType) => {
    if (parameterType === 'timezone') {
        return rule.blocks[0].parameters[1];
    }

    if (parameterType === 'schedule') {
        return rule.blocks[0].parameters[0];
    }
};

const updateDateTriggersCloud = (id, value, type, idGroup, parameterType = 'schedule') => {
    return (dispatch, getState) => {
        const state = getState();
        const { UPDATE_DATE_TRIGGER_CLOUD } = CloudMeshbotActionTypes;
        let ruleCloudTriggers = _.cloneDeep(state.meshBot.cloud.ruleCloudTriggers);

        const update = (list) => {
            return list.map((item) => {
                if (item.id === id) {
                    let parameters = getParametersByType(item, parameterType);

                    if (
                        parameters.name === type ||
                        parameters.name === 'timezone' ||
                        type === SCHEDULE_DATE_TYPES.SPECIAL_TIME_OF_THE_DAY
                    ) {
                        parameters = Object.assign(parameters, value);
                    }

                    return item;
                }

                return item;
            });
        };

        ruleCloudTriggers = observerTrigger(ruleCloudTriggers, update, idGroup);

        dispatch({ type: UPDATE_DATE_TRIGGER_CLOUD, ruleCloudTriggers });
    };
};

const addCloudAction = (data) => {
    return (dispatch, getState) => {
        const state = getState();
        const { ADD_CLOUD_ACTIONS } = CloudMeshbotActionTypes;
        let ruleCloudAction = _.cloneDeep(state.meshBot.cloud.ruleCloudAction);

        ruleCloudAction = [...ruleCloudAction, data];

        dispatch({ type: ADD_CLOUD_ACTIONS, ruleCloudAction });
    };
};

const deleteCloudActions = (id) => {
    return (dispatch, getState) => {
        const state = getState();
        const { DELETE_CLOUD_ACTIONS } = CloudMeshbotActionTypes;
        let ruleCloudAction = _.cloneDeep(state.meshBot.cloud.ruleCloudAction);

        const searchTrigger = (list) => {
            return list.filter((item) => item.id !== id);
        };

        ruleCloudAction = searchTrigger(ruleCloudAction);

        dispatch({ type: DELETE_CLOUD_ACTIONS, ruleCloudAction });
    };
};

const modifyTargetArray = (field, item, value) => {
    if (
        field === TARGET &&
        Array.isArray(value) &&
        item.isEditingState &&
        item.deviceParameters.find((key) => key.name === TARGET_VALUE)
    ) {
        item[field] = value;

        item.deviceParameters.find((key) => key.name === TARGET_VALUE).value = value.find(
            (key) => key.name === TARGET_VALUE,
        )?.value;

        if (item.deviceParameters.find((key) => key.name === SCALE)) {
            item.deviceParameters.find((key) => key.name === SCALE).value = value.find(
                (key) => key.name === SCALE,
            )?.value;
        }
    }

    return item;
};

const modifyTargetObject = (field, item, value) => {
    if (field === TARGET && !Array.isArray(value) && item.isEditingState) {
        const isDeviceParametersInclude = item.deviceParameters.find((key) => key.name);
        item[field] = value;
        if (isDeviceParametersInclude) {
            item.deviceParameters.find((key) => key.name).value = value.value;
            item.deviceParameters.find((key) => key.name).name = value?.name;
            item.deviceParameters.find((key) => key.name).transform = value?.transform;
        }
    }

    return item;
};

const modifyCommandObject = (field, item) => {
    if (field === COMMAND) {
        item[TARGET] = { value: '' };
        if (item.isEditingState && item.deviceParameters.find((key) => key.name === TARGET_DELTA)) {
            item.deviceParameters.find((key) => key.name === TARGET_DELTA).value = '';
            if (item.deviceParameters.find((key) => key.name === SCALE)) {
                item.deviceParameters.find((key) => key.name === SCALE).value = '';
            }
        }
    }

    return item;
};

// TODO: refactoring needed
// TODO: FIX NAMING: field -> "type" or "actionType" etc.
const updateActionCloud = (id, value, field) => {
    return (dispatch, getState) => {
        const state = getState();
        const { UPDATE_CLOUD_ACTIONS } = CloudMeshbotActionTypes;

        let ruleCloudAction = _.cloneDeep(state.meshBot.cloud.ruleCloudAction);
        // TODO: make as a saparate function, must be pure function
        const update = (list) => {
            return list.map((item) => {
                if (item.id === id) {
                    if (field === DASHBOARD) {
                        item[NOTIFICATION] = value;

                        return item;
                    } else {
                        delete item[NOTIFICATION];
                    }

                    if (field === CLOUD_NOTIFICATION) {
                        item[NOTIFICATION] = value;

                        return item;
                    }

                    modifyTargetArray(field, item, value);

                    modifyTargetObject(field, item, value);

                    if (field === ABSTRACT) {
                        item[field] = value;
                        item[COMMAND] = [];
                        item[CAPABILITY] = [];
                        item[CAPABILITY_COMMANDS] = [];
                        item[TARGET] = { value: '' };
                        if (item.isEditingState) {
                            item.deviceParameters.find((key) => key.abstract).abstract = value;
                        }
                    }

                    modifyCommandObject(field, item);

                    if (field === CAPABILITY) {
                        item[field] = value;
                        item[COMMAND] = '';
                        item[TARGET] = { value: '' };
                        item[INPUT_TYPE] = {};
                        if (item.isEditingState) {
                            item.deviceParameters.find((param) => param.capability).capability = value;
                        }
                    } else {
                        item[field] = value;
                        if (value === CLOUD_NOTIFICATION || value === DASHBOARD) {
                            delete item[ABSTRACT];
                            delete item[CAPABILITY];
                            delete item[CAPABILITY_COMMANDS];
                            delete item[COMMAND];
                            delete item[INPUT_TYPE];
                            delete item[TARGET];
                            delete item[NAME];
                            delete item[PAAS_ACTION];
                        }

                        if (value === DEVICE_STATE) {
                            delete item[NOTIFICATION];
                            delete item[NAME];
                            delete item[PAAS_ACTION];
                        }

                        if (value === PAAS_ACTION) {
                            item[NAME] = APICALL_NAME;
                            delete item[ABSTRACT];
                            delete item[CAPABILITY];
                            delete item[CAPABILITY_COMMANDS];
                            delete item[COMMAND];
                            delete item[INPUT_TYPE];
                            delete item[TARGET];
                            delete item[NOTIFICATION];
                        }

                        return item;
                    }
                }

                return item;
            });
        };
        ruleCloudAction = update(ruleCloudAction);

        dispatch({ type: UPDATE_CLOUD_ACTIONS, data: ruleCloudAction });
    };
};

const updateCloudRequiredAction = (id, required) => {
    return (dispatch, getState) => {
        const state = getState();
        const { UPDATE_CLOUD_REQUIRED_ACTION } = CloudMeshbotActionTypes;
        let ruleCloudAction = _.cloneDeep(state.meshBot.cloud.ruleCloudAction);

        const update = (list) => {
            return list.map((item) => {
                if (item.id === id) {
                    item[REQUIRED_FIELDS] = required;

                    return item;
                }

                return item;
            });
        };

        ruleCloudAction = update(ruleCloudAction);
        dispatch({ type: UPDATE_CLOUD_REQUIRED_ACTION, data: ruleCloudAction });
    };
};

const updateCloudRequiredTrigger = (id, required) => {
    return (dispatch, getState) => {
        const state = getState();
        const { UPDATE_CLOUD_REQUIRED_TRIGGER } = CloudMeshbotActionTypes;
        let ruleCloudTriggers = _.cloneDeep(state.meshBot.cloud.ruleCloudTriggers);

        const update = (list) => {
            return list.map((item) => {
                if (item.id === id) {
                    item[REQUIRED_FIELDS] = required;

                    return item;
                }

                return item;
            });
        };

        ruleCloudTriggers = update(ruleCloudTriggers);
        dispatch({ type: UPDATE_CLOUD_REQUIRED_TRIGGER, data: ruleCloudTriggers });
    };
};

const updateCloudActionMeta = (value, labels) => {
    return (dispatch) => {
        const { UPDATE_CLOUD_META } = CloudMeshbotActionTypes;

        switch (value) {
            case 'cloudNotification':
                return dispatch({ type: UPDATE_CLOUD_META, data: { type: 'notification', labels } });
            case 'dashboard':
                return dispatch({ type: UPDATE_CLOUD_META, data: { type: 'interaction', labels } });
            case PAAS_ACTION:
                return dispatch({ type: UPDATE_CLOUD_META, data: { type: PAAS_ACTION, labels } });
            default:
                return dispatch({ type: UPDATE_CLOUD_META, data: { labels } });
        }
    };
};

const updateCloudActionDragAndDrop = (items) => {
    return (dispatch, getState) => {
        const state = getState();

        const { UPDATE_CLOUD_ACTION_DRAG_AND_DROP } = CloudMeshbotActionTypes;
        let ruleCloudAction = _.cloneDeep(state.meshBot.cloud.ruleCloudAction);

        if (ruleCloudAction) {
            ruleCloudAction = items;
        }

        dispatch({ type: UPDATE_CLOUD_ACTION_DRAG_AND_DROP, data: ruleCloudAction });
    };
};

const setCloudMeshbotForEditing = (actions, triggers, parentOperator, name, meta) => (dispatch) => {
    dispatch(CloudMeshbotActions.setCloudTriggersForEdit(triggers, parentOperator));
    dispatch(CloudMeshbotActions.setCloudActionsForEdit(actions));
    dispatch(CloudMeshbotActions.setCloudMeshBotName(name));
    // Setting Meshbots labels in Redux store for editing changes.
    dispatch(MeshBotAction.setInitialCloudMeshBotLabels(meta));
};

const setCloudSceneForUI = (currentScene, abstracts, abstractsCapabilities) => (dispatch) => {
    const { triggers, actions, meta, name } = currentScene;
    const setCloudMeshbotForEdit = (actions, triggers, parentOperator) => {
        dispatch(CloudMeshbotActions.setCloudMeshbotForEditing(actions, triggers, parentOperator, name, meta));
    };

    if (Object.keys(currentScene).length) {
        const isGeoFencesTrigger =
            triggers.name === OPERATOR_NOT
                ? triggers?.parameters?.[INDEX_OF_ZERO]?.parameters?.[USER_BLOCK_INDEX]?.parameters?.[INDEX_OF_ZERO]
                      ?.capability === GEOFENCES
                : triggers?.parameters?.[USER_BLOCK_INDEX]?.parameters?.[INDEX_OF_ZERO]?.capability === GEOFENCES;

        const isNucalTrigger = checkIfNucalTrigger(triggers);

        const isDateAndTime =
            triggers.name === OPERATOR_NOT
                ? triggers?.parameters?.[INDEX_OF_ZERO].name === SCHEDULE
                : triggers?.name === SCHEDULE;

        if (isNucalTrigger) {
            const triggersForUI = convertNucalCloudMeshbotTriggersStructureToUI(triggers, SINGLE);
            const actionsForUI = convertCloudMeshbotActionsStructureToUI(
                actions,
                abstracts,
                Object.values(abstractsCapabilities),
            );

            setCloudMeshbotForEdit(actionsForUI, triggersForUI);
        } else if (isDateAndTime) {
            const triggersForUI = convertDateAndTimeCloudMeshbotTriggersStructureToUI(triggers);

            const actionsForUI = convertCloudMeshbotActionsStructureToUI(
                actions,
                abstracts,
                Object.values(abstractsCapabilities),
            );

            setCloudMeshbotForEdit(actionsForUI, triggersForUI);
        } else if (isGeoFencesTrigger) {
            const triggersForUI = convertGeofenceCloudMeshbotTriggersStructureToUI(triggers, SINGLE);

            const actionsForUI = convertCloudMeshbotActionsStructureToUI(
                actions,
                abstracts,
                Object.values(abstractsCapabilities),
            );

            setCloudMeshbotForEdit(actionsForUI, triggersForUI);
        } else {
            const triggersForUI =
                triggers?.name === OPERATOR_OR || triggers?.name === OPERATOR_AND
                    ? convertCloudMeshbotNestedTriggersStructureToUI(
                          triggers,
                          abstracts,
                          Object.values(abstractsCapabilities),
                      )
                    : convertCloudMeshbotTriggersStructureToUI(
                          triggers,
                          abstracts,
                          Object.values(abstractsCapabilities),
                      );
            const actionsForUI = convertCloudMeshbotActionsStructureToUI(
                actions,
                abstracts,
                Object.values(abstractsCapabilities),
            );

            setCloudMeshbotForEdit(actionsForUI, triggersForUI, triggers.name);
        }
    }
};

export const CloudMeshbotActions = {
    setCloudMeshBotNameBeforeEditing,
    setCloudMeshBotName,
    setCloudTriggersForEdit,
    setCloudActionsForEdit,
    addCloudTrigger,
    addCloudAction,
    updateCloudVariableInTrigger,
    updateCloudNucalSubscriptionInKvsExists,
    updateCloudNucalSubscriptionValueFromKvs,
    updateCloudTriggerForNuCAL,
    updateCloudVariablesTrigger,
    updateCloudVariablesTriggerBlocks,
    updateCloudTrigger,
    updateCurrentCloudMeshscene,
    updateCloudTriggerBlock,
    updateCloudTriggerFields,
    updateDateTriggersCloud,
    updateActionCloud,
    updateCloudActionMeta,
    updateCloudActionDragAndDrop,
    updateSelectedParentOperator,
    updateCloudRequiredAction,
    updateCloudRequiredTrigger,
    validateCloudTrigger,
    validateCloudTriggerEdit,
    validateCloudAction,
    validateCloudActionEdit,
    deleteCloudTrigger,
    deleteCloudActions,
    duplicateCloudMeshbotName,
    duplicateCloudMeshbot,
    clearCloudCurrentScene,
    clearTrigger,
    setCloudMeshbotForEditing,
    setCloudSceneForUI,
};
