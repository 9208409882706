import {
    EZLOGIC_TITLE_ACCOUNT_ADMINISTRATOR,
    EZLOGIC_TITLE_ACCOUNT_ADMINISTRATOR_DESC,
    EZLOGIC_TITLE_ACCOUNT_ADMINISTRATOR_PERMISSION,
    EZLOGIC_TITLE_ADVANECED_USER,
    EZLOGIC_TITLE_ADVANECED_USER_DESC,
    EZLOGIC_TITLE_ADVANECED_USER_PERMISSION,
    EZLOGIC_TITLE_BASIC_USER,
    EZLOGIC_TITLE_BASIC_USER_DESC,
    EZLOGIC_TITLE_BASIC_USER_PERMISSION,
    EZLOGIC_TITLE_CHANGE_PASSWORD,
    EZLOGIC_TITLE_ENTER_YOUR_PHONE_NUMBER,
    EZLOGIC_TITLE_LOWERCASE_LETTER,
    EZLOGIC_TITLE_MINIMUM_PASSWORD_LENGTH,
    EZLOGIC_TITLE_NEW_PASSWORD,
    EZLOGIC_TITLE_NEW_PIN_CODE,
    EZLOGIC_TITLE_NOTIFICATION_ONLY_RECIPIENT,
    EZLOGIC_TITLE_NOTIFICATION_ONLY_RECIPIENT_DESC,
    EZLOGIC_TITLE_NOTIFICATION_ONLY_RECIPIENT_PERMISSION,
    EZLOGIC_TITLE_OFF,
    EZLOGIC_TITLE_OFFLINE,
    EZLOGIC_TITLE_ONE_NUMBER_AND_NON_ALPHABETIC,
    EZLOGIC_TITLE_ONLINE,
    EZLOGIC_TITLE_PASSWORDS_MATCH,
    EZLOGIC_TITLE_PIN_CODE,
    EZLOGIC_TITLE_UPPERCASE_LETTER,
} from './language_tokens';
import hash from './uniqueHash';

export const EDIT_USER = 'editUser';
export const CREATE_USER = 'createUser';
export const CREATE_PIN_CODE = 'createPinCode';
export const EDIT_PIN_CODE = 'editPinCode';
export const CHANGE_USER_PASSWORD = 'changeUserPassword';
export const SUCCESS = 'success';

// TODO: REFACTORING NEEDED, JOIN ALL THE OBJECTS TOGETHER!
export const ACCOUNT_TYPES_VALUES = {
    BASIC_USER: 9,
    ACCOUNT_ADMINISTRATOR: 10,
    NOTIFICATION_ONLY_RECIPIENT: 11,
    ADVANCED_USER: 13,
};

// TODO: REFACTORING NEEDED, JOIN ALL THE OBJECTS TOGETHER!
export const ACCOUNT_TYPES = [
    {
        value: ACCOUNT_TYPES_VALUES.ACCOUNT_ADMINISTRATOR,
        name: EZLOGIC_TITLE_ACCOUNT_ADMINISTRATOR,
    },
    {
        value: ACCOUNT_TYPES_VALUES.ADVANCED_USER,
        name: EZLOGIC_TITLE_ADVANECED_USER,
    },
    {
        value: ACCOUNT_TYPES_VALUES.BASIC_USER,
        name: EZLOGIC_TITLE_BASIC_USER,
    },
    {
        value: ACCOUNT_TYPES_VALUES.NOTIFICATION_ONLY_RECIPIENT,
        name: EZLOGIC_TITLE_NOTIFICATION_ONLY_RECIPIENT,
    },
];

// TODO: REFACTORING NEEDED, JOIN ALL THE OBJECTS TOGETHER!
export const ACCOUNT_ADMIN_PERMISSION_ROLE = 10;

// TODO: REFACTORING NEEDED, JOIN ALL THE OBJECTS TOGETHER!
export const ACCOUNT_TYPES_INFO = [
    {
        value: ACCOUNT_TYPES_VALUES.ACCOUNT_ADMINISTRATOR,
        title: EZLOGIC_TITLE_ACCOUNT_ADMINISTRATOR,
        description: EZLOGIC_TITLE_ACCOUNT_ADMINISTRATOR_DESC,
    },
    {
        value: ACCOUNT_TYPES_VALUES.ADVANCED_USER,
        title: EZLOGIC_TITLE_ADVANECED_USER,
        description: EZLOGIC_TITLE_ADVANECED_USER_DESC,
    },
    {
        value: ACCOUNT_TYPES_VALUES.BASIC_USER,
        title: EZLOGIC_TITLE_BASIC_USER,
        description: EZLOGIC_TITLE_BASIC_USER_DESC,
    },
    {
        value: ACCOUNT_TYPES_VALUES.NOTIFICATION_ONLY_RECIPIENT,
        title: EZLOGIC_TITLE_NOTIFICATION_ONLY_RECIPIENT,
        description: EZLOGIC_TITLE_NOTIFICATION_ONLY_RECIPIENT_DESC,
    },
];

export const TOKEN_VERSION = 2;
export const PK_PERMISSION = 41;
export const MODE = 1;
export const RESEND_EMAIL_NUMBER = 1;
export const DATA_TYPES = {
    STRING: 'string',
};
export const PIN_CODE_ITEM_NAME = 'user_codes';

// TODO: REFACTORING NEEDED, JOIN ALL THE OBJECTS TOGETHER!
export const ACCOUNT_TYPES_PERMISSION_INFO = [
    {
        id: hash(),
        title: EZLOGIC_TITLE_NOTIFICATION_ONLY_RECIPIENT,
        value: ACCOUNT_TYPES_VALUES.NOTIFICATION_ONLY_RECIPIENT,
        permissionInfo: EZLOGIC_TITLE_NOTIFICATION_ONLY_RECIPIENT_PERMISSION,
        permissions: {
            createUser: false,
            createScenes: false,
            operateController: false,
            receiveNotifications: true,
        },
    },
    {
        id: hash(),
        title: EZLOGIC_TITLE_BASIC_USER,
        value: ACCOUNT_TYPES_VALUES.BASIC_USER,
        permissionInfo: EZLOGIC_TITLE_BASIC_USER_PERMISSION,
        permissions: {
            createUser: false,
            createScenes: false,
            operateController: true,
            receiveNotifications: true,
        },
    },
    {
        id: hash(),
        title: EZLOGIC_TITLE_ADVANECED_USER,
        value: ACCOUNT_TYPES_VALUES.ADVANCED_USER,
        permissionInfo: EZLOGIC_TITLE_ADVANECED_USER_PERMISSION,
        permissions: {
            createUser: false,
            createScenes: true,
            operateController: true,
            receiveNotifications: true,
        },
    },
    {
        id: hash(),
        title: EZLOGIC_TITLE_ACCOUNT_ADMINISTRATOR,
        value: ACCOUNT_TYPES_VALUES.ACCOUNT_ADMINISTRATOR,
        permissionInfo: EZLOGIC_TITLE_ACCOUNT_ADMINISTRATOR_PERMISSION,
        permissions: {
            createUser: true,
            createScenes: true,
            operateController: true,
            receiveNotifications: true,
        },
    },
];

export const CHANGE_PASSWORD_WARNING_TEXT =
    '*After successfully changing your password, you will be redirected to the login page.';

export const DEVICE_STATUS = {
    ONLINE: EZLOGIC_TITLE_ONLINE,
    OFFLINE: EZLOGIC_TITLE_OFFLINE,
};

export const PIN_CODE_API_ACTIONS = {
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete',
    ADD: 'add',
    SET: 'set',
    REMOVE: 'remove',
};

export const USER_CODE_TYPE = 'userCode';

export const ACCESS_CODE_LABEL = {
    EDIT: EZLOGIC_TITLE_NEW_PIN_CODE,
    CREATE: EZLOGIC_TITLE_PIN_CODE,
};

export const PIN_CODE_DEVICE_TYPE = {
    FOR_REQUEST: 'pinCodeDeviceForRequest',
};

export const PASSWORD_REQUIREMENTS = [
    { key: 'UppercaseLetter', requirementText: EZLOGIC_TITLE_UPPERCASE_LETTER, RegExp: /^(?=.*[A-Z])/ },
    { key: 'LowercaseLetter', requirementText: EZLOGIC_TITLE_LOWERCASE_LETTER, RegExp: /^(?=.*[a-z])/ },
    {
        key: 'OneNumber',
        requirementText: EZLOGIC_TITLE_ONE_NUMBER_AND_NON_ALPHABETIC,
        RegExp: /^(?=.*[0-9])(?=.*[\W_])/,
    },
    { key: 'Minimum8Characters', requirementText: EZLOGIC_TITLE_MINIMUM_PASSWORD_LENGTH, minimumCharacters: 8 },
];
export const PASSWORD_MUST_MATCH_KEY = 'passwordsMustMatch';
export const CONFIRM_PASSWORD_REQUIREMENTS = [
    { key: PASSWORD_MUST_MATCH_KEY, requirementText: EZLOGIC_TITLE_PASSWORDS_MATCH },
];

export const FIELD_TYPES = {
    TEXT: 'text',
    PASSWORD: 'password',
};

export const AUTO_COMPLETE_VALUES = {
    NEW_PASSWORD: EZLOGIC_TITLE_NEW_PASSWORD,
    OFF: EZLOGIC_TITLE_OFF,
};
export const DEFAULT_PHONE_COUNTRY = 'US';

export const TABLE_BUTTONS = {
    CHANGE_PASSWORD: EZLOGIC_TITLE_CHANGE_PASSWORD,
};

export const PHONE_NUMBER = {
    FIELD_LABEL: 'Phone',
    FIELD_NAME: 'Phone',
    NATIONAL_USA_MASK: '(999) 999-9999',
    PLACEHOLDER: EZLOGIC_TITLE_ENTER_YOUR_PHONE_NUMBER,
};

export const PHONE_NUMBER_INPUT_TYPES = {
    USA_FORMAT_WITH_MASK: 'USA_FORMAT_WITH_MASK',
    INTERNATIONAL_FORMAT_WITH_COUNTRY_SELECT: 'INTERNATIONAL_FORMAT_WITH_COUNTRY_SELECT',
};

export const REGEXs = {
    EVERYTHING_BUT_NUMBERS: /\D/g,
    USA_NATIONAL_PHONE_NUMBER_PATTERN: /^(\d{0,3})?(\d{0,3})?(\d{0,4})$/,
};

export const USER_TABLE_ACTIONS_TYPES = {
    ADMIN: 'ADMIN',
    SIMPLE: 'SIMPLE',
};
