import rootReducer from '../reducers';
import thunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';

const middleware = [thunk];

export default function configureEzlogicStore(preloadedState) {
    const store = configureStore({
        reducer: rootReducer,
        middleware: [...middleware],
        devTools: process.env.NODE_ENV !== 'production',
        preloadedState,
    });

    window.app = {};
    window.app.store = store;

    return store;
}

export const store = configureEzlogicStore();
